<template>
    <div>
        <MainHeader @toggle-sidebar="sidebarVisible = !sidebarVisible" />
        <MainSideNav
            v-if="isLoggedIn"
            :admin="isAdmin"
            v-model="sidebarVisible"
        />
        <v-content>
            <v-container style="max-width: 1024px">
                <transition name="fade" mode="out-in">
                    <router-view></router-view>
                </transition>
            </v-container>
        </v-content>

        <cookie-law theme="dark-lime" button-text="Selvä!">
            <template v-slot:message>
                Sivuillamme käytetään evästeitä, ja olemme velvollisia kertomaan
                siitä Sinulle
            </template>
        </cookie-law>
    </div>
</template>

<script>
    import MainHeader from "@/components/MainHeader";
    import MainSideNav from "@/components/MainSideNav";
    import CookieLaw from "vue-cookie-law";
    import { mapGetters, mapState } from "vuex";

    export default {
        name: "SiteFrame",
        components: { MainHeader, MainSideNav, CookieLaw },
        computed: {
            ...mapState(["user"]),
            ...mapGetters(["isLoggedIn", "isAdmin"])
        },
        data() {
            return {
                sidebarVisible: false
            };
        }
    };
</script>

<style>
    /* Fade transition */
    .fade-enter-active,
    .fade-leave-active {
        transition-duration: 0.25s;
        transition-property: opacity;
        transition-timing-function: ease;
    }

    .fade-enter,
    .fade-leave-active {
        opacity: 0;
    }
</style>
