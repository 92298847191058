<template>
    <v-navigation-drawer
        app
        clipped
        v-bind="$attrs"
        v-model="navOpen"
        :permanent="$vuetify.breakpoint.lgAndUp"
    >
        <v-list>
            <v-list-item v-if="isLoggedIn">
                <v-list-item-avatar>
                    <v-icon>mdi-account-card-details</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{ user.name }}</v-list-item-title>
                    <!-- <v-list-item-subtitle>Subtext</v-list-item-subtitle> -->
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item v-for="link in links" :key="link.url" :to="link.url">
                <v-list-item-action>
                    <v-icon>{{ link.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ link.title }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <template v-if="isAdmin">
                <v-subheader class="mt-4 text--darken-1">
                    PÄÄKÄYTTÄJÄN TOIMINNOT
                </v-subheader>

                <v-list-item
                    v-for="link in adminLinks"
                    :key="link.url"
                    :to="link.url"
                >
                    <v-list-item-action>
                        <v-icon>{{ link.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ link.title }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    import { mapGetters, mapState } from "vuex";

    export default {
        inheritAttrs: false,
        props: {
            admin: {
                type: Boolean,
                required: false,
                default: false
            },

            value: {
                required: false,
                type: [Boolean],
                default: false
            }
        },

        computed: {
            ...mapState(["user"]),
            ...mapGetters(["isLoggedIn", "isAdmin"]),
            navOpen: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit("input", val);
                }
            }
        },

        data() {
            return {
                links: [
                    { icon: "mdi-view-dashboard", title: "Etusivu", url: "/" },

                    {
                        icon: "mdi-file-document",
                        title: "Dokumentit",
                        url: "/docs"
                    },

                    {
                        icon: "mdi-dice-multiple",
                        title: "Satunnaista",
                        url: "/add-patient"
                    },
                    {
                        icon: "mdi-thumb-down",
                        title: "Rekisteröi kieltäytyjä",
                        url: "/add-rejector"
                    }
                ],
                adminLinks: [
                    {
                        icon: "mdi-account-group",
                        title: "Käyttäjien ylläpito",
                        url: "/admin/users"
                    },
                    {
                        icon: "mdi-file-document-box-plus",
                        title: "Dokumenttien ylläpito",
                        url: "/admin/documents"
                    },
                    {
                        icon: "mdi-chart-bar",
                        title: "Tilastot",
                        url: "/admin/stats"
                    },
                    {
                        icon: "mdi-history",
                        title: "Historia",
                        url: "/admin/history"
                    }
                ]
            };
        }
    };
</script>

<style scoped>
    >>> a.v-list-item--active {
        background-color: var(--v-accent-darken1);
        color: white;
    }
</style>