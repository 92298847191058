<template>
    <div>
        <h1 class="text-center display-3 my-5">
            Affelect
        </h1>
        <v-row>
            <v-col cols="12" md="6">
                <v-card>
                    <v-card-title>
                        Affelect-tutkimus
                    </v-card-title>
                    <v-card-text>
                        <p>
                            AFFELECT-tutkimus on satunnaistettu kontrolloitu
                            tutkimus, jossa tutkitaan akuutin eteisvärinän ja
                            eteislepatuskohtausten hoitoa. Tutkimuksessa
                            vertaillaan kahden eri hoitovaihtoehdon
                            turvallisuutta ja niiden vaikutusta potilaiden
                            oireisuuteen.
                        </p>
                        <p>
                            Tutkimus toteutetaan useassa eri keskuksessa
                            samanaikaisesti (TAYS, KHKS, PHKS ja KSKS)
                        </p>
                        <p>
                            Tutkimukseen osallistujat valikoidaan
                            lääketieteellisin perustein.
                        </p>
                        <p>
                            Tutkimukseen osallistumista voidaan harkita jos
                            potilaalle suunnitellaan päivystyksellistä
                            rytminhallintahoitoa hemodynaamisesti vakaaseen ja
                            tuoreeksi oletettuun eteisvärinä tai
                            eteislepatuskohtaukseen (oireiden alusta &lt;48
                            tuntia).
                        </p>

                        <p>
                            Potilaan syketaso on oltava levossa alle 110
                            lyöntiä/min joko lääkittynä tai ilman
                        </p>

                        <p>
                            Jokainen potilas voi osallistua tutkimukseen vain
                            kerran. Potilaan soveltuvuuden tutkimukseen päättää
                            potilasta hoitava lääkäri.
                        </p>

                        <v-card-actions v-if="!isLoggedIn">
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" :to="{ name: 'login' }">
                                Kirjaudu sisään
                            </v-btn>
                        </v-card-actions>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card>
                    <v-card-title>
                        Tutkimuksesta vastaava henkilö
                    </v-card-title>
                    <v-card-text>
                        <v-list>
                            <v-list-item three-line>
                                <v-list-item-action>
                                    <v-icon>mdi-account</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Jussi Hernesniemi
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        Kardiologian erikoislääkäri<br />
                                        Dosentti ja lääketieteen tohtori
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item two-line>
                                <v-list-item-action>
                                    <v-icon>mdi-hospital-marker</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    Tays Sydänsairaala (Tays Sydänkeskus Oy)
                                    ja<br />
                                    Tampereen yliopisto
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-action>
                                    <v-icon>mdi-email</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    etunimi.sukunimi@sydansairaala.fi
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";

    export default {
        computed: {
            ...mapGetters(["isLoggedIn"]),
            ...mapState(["user"])
        },

        methods: {
            logout() {
                this.$http.post("/logout").then(() => this.$store.commit("logout"));
            }
        }
    };
</script>

<style scoped>
</style>