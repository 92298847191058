// Vuetify Dialogs
// https://github.com/yariksav/vuetify-dialog#readme
import Vue from "vue";
import vuetify from './vuetify';
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'

Vue.use(VuetifyDialog, {
    context: {
        vuetify
    },
    warning: {
        icon: 'mdi-alert',
        actions: {
            false: 'Peruuta',
            true: {
                text: 'Kyllä',
                color: 'primary'
            }
        }
    },
    error: {
        icon: 'mdi-alert-circle',
        actions: {
            false: 'Peruuta',
            true: {
                text: 'Kyllä',
                color: 'primary'
            }
        }
    }
})
