import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: null
    },

    mutations: {
        login(state, user) {
            state.user = user;
        },

        logout(state) {
            state.user = []
        }
    },

    getters: {
        isLoggedIn: state => {
            console.log(state)
            return state.user && state.user.id;
        },

        isAdmin(state) {
            return state.user && state.user.is_admin;
        }
    }
})
