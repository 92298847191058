import Vue from "vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";
import store from "./store/index";
import i18n from "./plugins/i18n";
import VueGtag from "vue-gtag";
import "./plugins/dialogs";
import "./plugins/axios";

// Styles
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.config.productionTip = false;

Vue.use(
    VueGtag,
    {
        config: {
            id: "UA-2760839-47"
            // params: {
            //     send_page_view: false
            // }
        },
        appName: "Affelect",
        pageTrackerScreenviewEnabled: true
    },
    router
);

new Vue({
    i18n,
    store,
    router,
    vuetify,
    render: h => h(App)
}).$mount("#app");
