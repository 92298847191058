import Vue from "vue";
import VueRouter from "vue-router";
import SiteFrame from "../SiteFrame.vue";
import Frontpage from "../views/Frontpage.vue";
import Login from "../views/Login.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [{
        path: "/kirjaudu",
        name: "login",
        component: Login
    },
    {
        path: "/",
        component: SiteFrame,
        children: [
            // TODO: Linkit ovat piilossa, mutta routelle ei ole guardia
            {
                path: "/",
                name: "home",
                component: Frontpage
            },
            {
                path: "/add-patient",
                name: "add-patient",
                component: () =>
                    import(
                        /* webpackChunkName: "add-entry" */
                        "../views/AddPatient.vue"
                    )
            },
            {
                path: "/add-rejector",
                name: "add-rejector",
                component: () =>
                    import(
                        /* webpackChunkName: "add-entry" */
                        "../views/AddRejector.vue"
                    )
            },
            {
                path: "/patient/:id",
                name: "show-patient",
                component: () =>
                    import(
                        /* webpackChunkName: "show-patient" */
                        "../views/ShowPatient.vue"
                    )
            },

            {
                path: "/docs",
                name: "docs",
                component: () =>
                    import(
                        /* webpackChunkName: "docs" */
                        "../views/Documents.vue"
                    )
            }
        ]
    },
    {
        path: "/admin",
        component: SiteFrame,
        children: [{
                path: "users",
                component: () =>
                    import(
                        /* webpackChunkName: "admin" */
                        "../views/UsersAdmin.vue"
                    )
            },
            {
                path: "documents",
                component: () =>
                    import(
                        /* webpackChunkName: "admin" */
                        "../views/DocumentsAdmin.vue"
                    )
            },
            {
                path: "history",
                name: "history",
                component: () =>
                    import(
                        /* webpackChunkName: "patients-history" */
                        "../views/PatientsHistory.vue"
                    )
            },
            {
                path: "stats",
                name: "stats",
                component: () =>
                    import(
                        /* webpackChunkName: "stats" */
                        "../views/Statistics.vue"
                    )
            }
        ]
    },
    {
        path: "*",
        redirect: "/"
    }
    // {
    //     path: "/about",
    //     name: "about",
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () =>
    //         import( /* webpackChunkName: "about" */ "../views/About.vue")
    // }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

// Quite generic guard for all routes!
router.beforeEach(async (to, from, next) => {

    async function wait(ms) {
        return new Promise(resolve => {
            setTimeout(resolve, ms);
        });
    }

    if (to.name === 'home') {
        return next();
    }

    while (store.state.user === null) {
        await wait(10);
    }

    if (to.name === 'login') {
        if (store.getters.isLoggedIn) {
            return next({
                name: 'home'
            });
        }

        return next();
    }

    if (to.path.startsWith('/admin')) {
        if (store.getters.isAdmin) {
            next();
        } else {
            next({
                name: "home"
            });
        }

        return;
    }

    if (!store.getters.isLoggedIn) {
        next({
            name: "login"
        });
    }

    return next();
});

export default router;
