import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import router from "../router";

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        // Not logged in
        if (error.request.status === 401) {
            router.push({ name: "login" });
        }

        return Promise.reject(error);
    }
);

Vue.use(VueAxios, axios);
