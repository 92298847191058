<template>
    <v-app>
        <router-view></router-view>
    </v-app>
</template>

<script>
    import HelloWorld from "./components/HelloWorld";
    import { mapGetters } from "vuex";

    export default {
        name: "App",

        components: {
            HelloWorld
        },

        data: () => ({
            loading: true
        }),

        computed: {
            ...mapGetters(["isLoggedIn"])
        },

        created() {
            this.$http.get("/api/user").then(response => {
                this.$store.commit("login", response.data);
                this.loading = false;
            });
        }
    };
</script>
