<template>
    <v-app-bar app color="secondary" clipped-left>
        <v-app-bar-nav-icon
            @click.stop="toggleSidebar"
            v-if="$vuetify.breakpoint.mdAndDown && isLoggedIn"
        ></v-app-bar-nav-icon>

        <div class="d-flex align-center">
            <v-icon large class="mr-2" color="red">mdi-heart-pulse</v-icon>
            <h1>Affelect</h1>
        </div>

        <v-spacer></v-spacer>

        <v-btn @click="toggleDarkTheme" icon>
            <v-icon>mdi-theme-light-dark</v-icon>
        </v-btn>

        <template v-if="isLoggedIn">
            <v-btn @click="logout" text :icon="$vuetify.breakpoint.smAndDown">
                <v-icon v-if="$vuetify.breakpoint.smAndDown">mdi-logout</v-icon>
                <span v-else>
                    Kirjaudu ulos
                </span>
            </v-btn>
        </template>
        <template v-else>
            <v-btn
                text
                :to="{ name: 'login' }"
                :icon="$vuetify.breakpoint.smAndDown"
            >
                <v-icon v-if="$vuetify.breakpoint.smAndDown">mdi-login</v-icon>
                <span v-else>
                    Kirjaudu sisään
                </span>
            </v-btn>
        </template>
    </v-app-bar>
</template>

<script>
    import { mapGetters, mapState } from "vuex";

    export default {
        computed: {
            ...mapState(["user"]),
            ...mapGetters(["isLoggedIn", "isAdmin"])
        },
        methods: {
            logout() {
                this.$http
                    .post("/logout")
                    .then(() => this.$store.commit("logout"))
                    .then(() => this.$router.push("/"));
            },

            toggleSidebar() {
                this.$emit("toggle-sidebar");
            },

            toggleDarkTheme() {
                this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            }
        },

        created() {
            this.$vuetify.theme.dark = matchMedia(
                "(prefers-color-scheme: dark)"
            ).matches;

            matchMedia("(prefers-color-scheme: dark)").addListener(
                q => (this.$vuetify.theme.dark = q.matches)
            );
        }
    };
</script>

<style scoped>
</style>