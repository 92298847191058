<template>
    <v-menu bottom left>
        <template v-slot:activator="{ on }">
            <v-btn dark icon v-on="on">
                <v-icon>mdi-translate</v-icon>
            </v-btn>
        </template>

        <v-list>
            <v-list-item
                v-for="lang in langs"
                :key="lang.code"
                @click="$i18n.locale = lang.code"
            >
                <v-list-item-title>{{ lang.name }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
    export default {
        data() {
            return {
                langs: [
                    { code: "fi", name: "Suomi" },
                    { code: "en", name: "English" }
                ]
            };
        }
    };
</script>

<style scoped>
</style>