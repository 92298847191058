<template>
    <v-content>
        <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="8" md="4">
                    <v-card class="elevation-12">
                        <v-toolbar color="primary" dark flat>
                            <v-toolbar-title>
                                {{ $t("login.loginLabel") }}
                            </v-toolbar-title>

                            <v-spacer />
                            <!--
                            <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                    <LocaleChanger />
                                </template>
                                <span>{{
                                    $t("login.ChangeLocaleTooltip")
                                }}</span>
                            </v-tooltip>
                            -->
                        </v-toolbar>
                        <v-card-text>
                            <v-alert
                                type="error"
                                v-model="loginFailed"
                                dismissible
                                prominent
                            >
                                {{ $t("login.invalidCredentialsLabel") }}
                            </v-alert>

                            <v-form ref="form" v-model="valid" lazy-validation>
                                <v-text-field
                                    :label="$t('login.accountName')"
                                    name="login"
                                    prepend-icon="mdi-account"
                                    type="text"
                                    v-model="username"
                                    :rules="[requiredRule]"
                                />

                                <v-text-field
                                    @keydown.enter="login"
                                    id="password"
                                    :label="$t('login.password')"
                                    name="password"
                                    prepend-icon="mdi-lock"
                                    type="password"
                                    v-model="password"
                                    :rules="[requiredRule]"
                                />
                            </v-form>

                            <v-alert type="info" prominent class="mt-4">
                                {{ $t("login.passwordInfoText") }}
                            </v-alert>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer />
                            <v-btn text to="/">
                                {{ $t("common.Cancel") }}
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                type="submit"
                                @click="login"
                            >
                                {{ $t("login.loginButtonText") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-content>
</template>

<script>
    import LocaleChanger from "@/components/LocaleChanger.vue";

    export default {
        components: { LocaleChanger },
        data() {
            return {
                username: "",
                password: "",
                valid: true,
                loading: false,
                loginFailed: false,

                requiredRule: v => !!v || this.$t("common.requiredField")
            };
        },

        methods: {
            login() {
                if (!this.$refs.form.validate()) {
                    return;
                }

                this.loginFailed = false;
                const url = "login";

                this.$http
                    .post(url, {
                        username: this.username,
                        password: this.password
                    })
                    .then(response => {
                        this.$store.commit("login", response.data.user);
                        this.$router.replace("/");
                    })
                    .catch(err => (this.loginFailed = true));
            }
        }
    };
</script>

<style scoped>
</style>